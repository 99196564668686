<template>
	<div>
		<el-dialog :title="label_title" :visible.sync="dialogVisible" width="30%" center :before-close="handleClose">
			<el-form :model="ruleForm" status-icon label-width="100px" class="demo-ruleForm">
				<el-form-item label="巡查类型" label-width="120px">
					<el-select v-model="ruleForm.type" placeholder="请选择巡查类型" @change="goto_Roles" style="width: 60%;">
						<el-option v-for="item in prov_xuncha" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="巡查项目类型" label-width="120px">
					<el-select v-model="ruleForm.inspection_category_id" placeholder="请选择巡查项目类型" style="width: 60%;">
						<el-option v-for="item in provinceids" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<div class="el-card" v-for="(item,index) in ruleForm.content" :key="index">
					<div>
						<el-form-item label="巡查项目名称">
							<el-input v-model="ruleForm.content[index].inspect_type" placeholder="巡查项目名称">

							</el-input>
						</el-form-item>
						<div class="flexs" ref="mina_content" v-for="(item,key) in ruleForm.content[index].list"
							:key="key">
							<el-form-item label="巡查内容">
								<el-input v-model="ruleForm.content[index].list[key].content"
								  type="textarea"
								  :rows="4"
									style="width: 100%; margin-bottom: 15px;" placeholder="巡查内容"></el-input>
								<el-select v-model="ruleForm.content[index].list[key].user_type" multiple
									placeholder="请选择巡查单位" style="width: 100%;">
									<el-option v-for="item in provinceid" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>

							</el-form-item>
							<el-button circle type="success" style="margin-left: 16px;" icon="el-icon-circle-plus"
								@click="addContent(index,key)">
							</el-button>
							<el-button circle type="danger" v-if="ruleForm.content[index].list.length>1"
								style="margin-left: 16px;" icon="el-icon-delete" @click="deleteContent1(index,key)">
							</el-button>
						</div>

					</div>
					<div class="flexs">
						<el-button circle type="success" style="flexs;" @click="addInput(index)"><i
								class="el-icon-circle-plus"></i>
						</el-button>
						<el-button v-if="ruleForm.content.length>1" circle type="danger" style="flexs;"
							@click="deleteInput(index)" icon="el-icon-delete">
						</el-button>
					</div>
				</div>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" @click="increase_tion(index,key)">新增</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		get_InspectionCategorys,
		getRoles,
		Inspec_Records
	} from "@/api/manage.js"
	export default {
		name: 'add',

		data() {
			return {
				dialogVisible: false,
				label_title: "",
				provinceid: [],
				provinceids: [],
				prov_xuncha: [{
						id: 1,
						name: "市场自查"
					},
					{
						id: 2,
						name: "监管人员巡查"
					}
				],
				ruleForm: {
					type: "",
					inspection_category_id: "",
					content: [{
						inspect_type: "",
						list: [{
							content: "",
							user_type: [],
						}]
					}]
				},
				nun_type: 0,
				//动态添加输入框
				isactivu: [],
				contentlist: [],
				contentlists: [],
				purPeoList: []
			}
		},
		created() {
			this.Get_Roles()
			if (this.nun_type === 2) {
				this.mina_content()
			}


		},
		methods: {
			mina_content(num) {
				Inspec_Records(num).then(res => {
					if (this.nun_type === 2) {
						this.ruleForm.type = res.data.type
						this.ruleForm.content = res.data.content
						this.goto_Roles()
						this.ruleForm.inspection_category_id = Number(res.data.inspection_category_id) 
						console.log(this.ruleForm.inspection_category_id);
					} else {

					}


				})
			},
			//获取单位类型
			Get_Roles() {
				getRoles().then(res => {
					this.provinceid = res.data
				})
			},
			handleClose() {
				// alert()
				this.dialogVisible = false

				if (this.nun_type === 1) {
					this.ruleForm.type = ''
					this.ruleForm.inspection_category_id = ''
					this.ruleForm.content = [{
						inspect_type: "",
						list: [{
							content: "",
							user_type: [],
						}]
					}]
				} else if (this.nun_type === 2) {

					console.log(this.ruleForm.content);
					// this.ruleForm.content = this.ruleForm.content.map((item) => {
					//            	item.inspect_type=""
					// 						item.list.map((item1)=>{
					// 							item1.content=""
					// 							item1.user_type=[]
					// 						})
					//           })
				}

			},
			addContent(index) {
				let datalist = this.ruleForm.content
				datalist[index].list.push({
					content: "",
					user_type: [],
				})
			},
			deleteContent1(ids, index) {
				let datalist = this.ruleForm.content

				datalist. [ids].list.splice(index, 1)


			},
			addInput(index) {
				// this.isactivu=[]
				this.ruleForm.content.push({
					inspect_type: "",
					list: [{
						content: "",
						user_type: [],
					}]
				})

			},
			deleteInput(index) {
				console.log(index);
				this.ruleForm.content.splice(index, 1)
			},


			goto_Roles(valeu) {
				console.log(valeu);
				get_InspectionCategorys({
					type:this.ruleForm.type
				}).then(res => {
					this.provinceids = res.data
				})
			},
			increase_tion(index, ids) {
				if (this.nun_type === 1) {
					this.$emit("new_data", this.ruleForm)
					this.handleClose()
					this.dialogVisible = false
				} else {
					this.$emit("update_data", this.ruleForm)
					// this.handleClose()
					this.dialogVisible = false
				}

			}


		}
	}
</script>

<style lang="less" scoped>
	.flexs {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
	}

	.el-card {
		border-radius: 4px;
		border: 1px solid #EBEEF5;
		background-color: #f0f0f0;
		overflow: hidden;
		color: #303133;
		transition: .3s;
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
		padding: 15px;
		margin-bottom: 20px;
	}
</style>
