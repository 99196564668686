<template>
  <div>
    <el-row style="margin-bottom: 20px">
       <el-col :span="3" style="margin-right: 15px;">
         <el-select v-model="ruleForm.user_type" placeholder="请选择巡察组">
           <el-option
             v-for="item in provinceid"
             :key="item.id"
             :label="item.name"
             :value="item.id"
           ></el-option>
         </el-select>
       </el-col>
   <!--    <el-col :span="3" style="margin-right: 15px;">
        	<el-input v-model="ruleForm.name" placeholder="请输入内容"></el-input>
       </el-col> -->
      <!-- <el-col :span="4" style="margin-right: 15px;">
         	<el-input v-model="ruleForm.province_id" placeholder="请输入内容"></el-input>
       </el-col> -->
           
       <el-col :span="4" >
         <el-button type="primary" @click="init()">查询</el-button>
       	<el-button type="" @click="addMaint(1)">新增</el-button>
       </el-col>
    </el-row>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="编号"> </el-table-column>
      <el-table-column prop="name" label="检查项目名称"> </el-table-column>
      <el-table-column prop="patrol_roles_name" label="巡查角色"> </el-table-column>
			<el-table-column prop="type" label="巡查类型">
				<template slot-scope="scope">
					<span v-if="scope.row.type===1">市场开办方</span>
					<span v-if="scope.row.type===2">监管人员</span>
				</template>
				</el-table-column>

      <el-table-column prop="created_at" label="添加时间"> </el-table-column>
      <el-table-column prop="" label="编辑"> 
			 <template slot-scope="scope">
			 	<el-button @click="addMaint(2,scope.row)">编辑</el-button>
			 </template>
			</el-table-column>
    </el-table>
		<div style="display: flex;justify-content: center;align-items: center;margin-top: 15px">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="ruleForm.page" :page-sizes="[10, 20, 30]" :page-size="ruleForm.limit"
				layout="total, sizes, prev, pager, next, jumper" :total="ruleForm.total">
			</el-pagination>
		</div>
		<addList ref="add_List"  @new_data="seaveCompanys" @update_data="update_Companys" ></addList>
  </div>
</template>

<script>
import AeraInfo from '@/assets/pca-code.json'
import addList from "./add.vue"
import {get_Categorys,post_Inspection,update_Records,getRoles} from "@/api/manage.js"
export default {
  name: 'list',
	components:{
		addList
	},
  data () {
	
    return {
      options: AeraInfo,
			dialogisible:false,
      selectedOptions: [],
      provinceid: [],
      ruleForm: {
				name:"",
        province_id: '',
				user_type:"",
				page: 1,
				limit: 10,
				total: 0
      },
      tableData: [],
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      // dialogVisible: false //
			ids:""
    }
  },
  created () {
    this.init()
		this.Get_Roles()
  },
  methods: {
     //获取单位类型
     Get_Roles() {
     	getRoles().then(res => {
     		this.provinceid = res.data
     	})
     },
    // handleClose () {},
		init(){
			get_Categorys(this.ruleForm).then(res=>{
				this.tableData=res.data.data
				this.ruleForm.total = res.data.total
			})
		},
		addMaint(num,item){	
			     
						this.$refs.add_List.dialogVisible = true
						if(num===1){
							this.$refs.add_List.label_title="新增巡查项目"
							  this.$refs.add_List.ruleForm.type = ''
							  this.$refs.add_List.ruleForm.inspection_category_id = ''
							  this.$refs.add_List.ruleForm.content = [{
							          	inspect_type: "",
							          	list: [{
							          		content: "",
							          		user_type: [],
							          	}]
							          }]
							this.$refs.add_List.nun_type=1
							// this.$refs.add_List.handleClose(1)
						}else if(num===2){
							 this.ids=item.id
							 console.log(item.id)
							 	// this.$refs.add_List.()
								this.$refs.add_List.label_title="编辑巡查项目"
								this.$refs.add_List.mina_content(item.id)
									this.$refs.add_List.nun_type=2
						}
						
						
		},
		handleCurrentChange(){
			
		},
		handleSizeChange(){
			
		},
		//新增巡查项目
		seaveCompanys(data){
	      this.$refs.add_List.dialogVisible = true
			post_Inspection({
				inspection_category_id:data.inspection_category_id,
				content:JSON.stringify(data.content)
			}).then(res=>{			
			if(res.code==200){
					this.init()
					this.$message.success(res.data)
				}
			})
		},
		update_Companys(data){
			update_Records({
				inspection_category_id:data.inspection_category_id,
				content:JSON.stringify(data.content)
			},this.ids).then(res=>{
			if(res.code==200){
					this.init()
					this.$message.success(res.data)
				}
			})
		}
  }
}
</script>

<style scoped></style>
